import { AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation } from "react-router-dom";
import Artists from "../app/Home/Artists/Artists";
import useRouteService from "../app/Hooks/useRouteService";
import { Home } from "../app/index";
import Landing from "../app/Landing/Landing";
import PageNotFound from "../app/PageNotFound/PageNotFound";
import Shop from "../app/Shop/Shop";
import { RoutePattern } from "./RoutePattern";

export default function AppRoutes() {
  const location = useLocation();
  const { setCurrentRoute } = useRouteService();

  setCurrentRoute(window.location.pathname);

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path={"/"} element={<Landing />} />
        <Route path="/home" element={<Home />} />
        <Route path="/roster" element={<Artists />} />
        <Route path="/shop" element={<Shop />} />
        {/* <Route path={"/" + RoutePattern.ARTISTS} element={<Artists />} /> */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </AnimatePresence>
  );
}
