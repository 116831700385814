import AWKWARD_MP4 from "./assets/videos/awkward.mp4";
import LOUIE_MP4 from "./assets/videos/louie-v.mp4";
import TELLEM_MP4 from "./assets/videos/tell-em.mp4";
import DOJA_MP4 from "./assets/videos/doja.mp4";

export const BASE_ASSET_URL =
  "https://github.com/kevin700brands/markow-assets/raw/refs/heads/main";

export const MyAssets = {
  LOGO_GIF: BASE_ASSET_URL + "/images/logo.gif",
  LOGO_IMG: BASE_ASSET_URL + "/images/logo.png",
  AWKWARD_MP4,
  LOUIE_MP4,
  TELLEM_MP4,
  DOJA_MP4,
};
