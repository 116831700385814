import { BASE_ASSET_URL } from "../../../assets";

export const data = [
  {
    name: "$NOT",
    img: BASE_ASSET_URL + "/images/$NOT img.png",
    link: "https://open.spotify.com/artist/5IbEL2xjRtKsunfmsahLuO?si=aA3bkeRET5m8VloH25JFLw&nd=1",
  },

  {
    name: "Eem Triplin",
    img: BASE_ASSET_URL + "/images/Eem Triplin img.png",
    link: "https://open.spotify.com/artist/5kxnZh8gXyXdIvCWbDMevT?si=ixC_5nv3RkKp3BeUTAEQKg",
  },
  {
    name: "Scarlet House",
    img: BASE_ASSET_URL + "/images/Scarlet House img.png",
    link: "https://open.spotify.com/artist/7jWdMH4K9omZ4z2UkGkz6N?si=xmkJgHObSnWR--Z_n1_ViQ",
  },
];
