import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigation, SocialIcons } from "./components";
import AppRoutes from "./routes/AppRoutes";
import { RootState } from "./Store";

export default function App() {
  const { currentRoute } = useSelector((state: RootState) => state.Routes);
  return (
    <>
      <Router>
        {currentRoute !== "/" ? <Navigation /> : null}
        <Routes>
          <Route path="*" element={<AppRoutes />} />
        </Routes>
        <div className="w-full z-50">
          <SocialIcons />
        </div>
      </Router>
    </>
  );
}
